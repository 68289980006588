<!--
 * @Descripttion: 预约演示
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-09 16:31:34
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-13 14:07:42
-->
<template>
    <div class="demonstration">
        <div class="d-w-title flex-sbc">
            <div class="wt-title flex-wc" @click="clickGoBalck">
                <div class="balck">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="title">预约演示</div>
            </div>
            <div class="wt-logo">
                <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/e67d3671835aaedf99c9631d006ac7ca.png" alt="">
            </div>
        </div>
        <div class="d-w-content">
            <div class="dw-c-title flex-sbc">
                <div class="t-left">
                    <div class="title">预约演示</div>
                    <div class="tag">预约线上演示，更好了解系统功能</div>
                </div>
                <div class="t-right">
                    <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/b1e7f92b2147a15a3d3541a5ef2bb959.png" alt="">
                </div>
            </div>
            <div class="dw-c-box">
                <div class="cb-title">联系信息</div>
                <div class="cb-item">
                    <div class="i-title">姓名<span>*</span></div>
                    <div class="i-content">
                        <el-input v-model="info.name" placeholder="请输入姓名"></el-input>
                    </div>
                </div>
                <div class="cb-item">
                    <div class="i-title">电话<span>*</span></div>
                    <div class="i-content">
                        <el-input v-model="info.phone" placeholder="请输入电话"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-w-btn">
            <div class="btn flex-cc" @click="clickDemo">预约演示</div>
            <div class="tip">点击“预约演示”，即表示我授权有库云通过我提供的联系信息与我联系，沟通预定演示时间及方式。</div>
            <div class="code">东有创新©粤TCP备2023127805号-1</div>
        </div>
    </div>
</template>

<script>
import api from '@/utils/common.js';
export default {
    data () {
        return {
            info: {
                name: '',
                phone: ''
            }
        }
    },
    onLoad () {

    },
    methods: {
        clickDemo(){
            if(this.info.name == ''){
                this.$message.error('请输入姓名')
                return
            }
            if(this.info.phone == ''){
                this.$message.error('请输入电话')
                return
            }
            let params = {
                name: this.info.name,
                phone: this.info.phone,
            }
            api.post('/api/common/user_reservation',params, (res) => {
                this.$message.success('已预约')
                setTimeout(() => {
                    this.$router.go(-1) 
                }, 1000);
            });
        },
        clickGoBalck(){
            this.$router.go(-1) 
        },
    },
}
</script>

<style lang='scss'>
.demonstration{
    height: 100vh;
    position: relative;
    .d-w-title{
        height: 50px;
        background: #fff;
        padding: 0 20px;
        .wt-title{
            .balck{
                .el-icon-arrow-left{
                    font-size: 16px;
                    color: #101010;
                    font-weight: bold;
                }
            }
            .title{
                font-weight: bold;
                font-size: 16px;
                color: #101010;
                margin-left: 10px;
                
            }
        }
        .wt-logo{
            .logo{
                width: 70px;
                height: 15px;
            }
        }
    }
    .d-w-content{
        .dw-c-title{
            background: #f7f7f7;
            height: 85px;
            .t-left{
                padding-left: 20px;
                .title{
                    font-size: 24px;
                    font-weight: bold;
                    color: #101010;
                }
                .tag{
                    font-size: 13px;
                    color: #5a5a5a;
                    padding-top: 10px;
                }
            }
            .t-right{
                .img{
                    width: 128px;
                    height: 85px;
                }
            }
        }
        .dw-c-box{
            padding: 60px 20px;
            .cb-title{
                padding-top: 20px;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
            }
            .cb-item{
                .i-title{
                    font-size: 16px;
                    color: #101010;
                    padding: 20px 0;
                    span{
                        color: #ff0000;
                    }
                }
                .i-content{
                    .el-input{
                        border: none;
                        background: #f7f7f7;
                    }
                    .el-input__inner{
                        border: none;
                        background: #f7f7f7;
                        height: 45px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
    .d-w-btn{
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 20px;
        .btn{
            width: 100%;
            background: #0068ff;
            color: #fff;
            font-size: 16px;
            height: 45px;
            border-radius: 4px;
        }
        .tip{
            font-size: 13px;
            color: #5a5a5a;
            padding: 10px 0;
            line-height: 22px;
        }
        .code{
            text-align: center;
            font-size: 12px;
            color: #7b7b7b;
            padding-top: 10px;
        }
    }
}
</style>
